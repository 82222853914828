
import Swiper from "swiper";
export default {
    name: 'partners',
    components: {
    },
    data() {
        return {
            swiperPatnersListOne: [
                require('../img/服务伙伴01.png'),
                require('../img/服务伙伴02.png'),
                require('../img/服务伙伴03.png'),
                require('../img/服务伙伴04.png'),
            ],// 服务伙伴轮播
            swiperPatnersList: [
                require('../img/营销伙伴01.png'),
                require('../img/营销伙伴02.png'),
                require('../img/营销伙伴03.png'),
                require('../img/营销伙伴04.png'),
                require('../img/营销伙伴05.png'),
                require('../img/营销伙伴06.png'),
                require('../img/营销伙伴07.png'),
                require('../img/营销伙伴08.png'),
                require('../img/营销伙伴09.png'),
            ],// 合作伙伴轮播
            marketingList: [],
            partnersSwiperOne: null,
            partnersSwiperCustom: null,
            elmentList:
                [
                    {
                        pcElementId: 404,
                        phoneElementId: 401,
                        descList: [
                            {
                                descName: 'Cooperation Partner',
                                sort: 1
                            }, {
                                descName: '',
                                sort: 2
                            },
                            {
                                descName: '',
                                sort: 3
                            }],
                        type: 'image',
                        sort: 1
                    },
                    {
                        pcElementId: 405,
                        phoneElementId: 402,
                        descList: [
                            {
                                descName: 'Cooperation Partner',
                                sort: 1
                            }, {
                                descName: 'Sevnce Robotics has established partnerships with numerous suppliers and service providers. Through sincere cooperation and win-win cooperation with these excellent partners, it can achieve resource sharing and complementary advantages, thereby improving product quality, reducing costs, and enhancing market competitiveness.',
                                sort: 2
                            }],
                        type: 'image',
                        sort: 2
                    },
                    {
                        pcElementId: 406,
                        phoneElementId: 403,
                        descList: [
                            {
                                descName: 'Marketing Partner',
                                sort: 1
                            }, {
                                descName: 'Adhering to the cooperation concept of "win-win cooperation, hard work and harvest", Sevnce Robotics establish close cooperative relations with agents and dealer partners to jointly promote the landing of products and solutions, relying on the rich professional knowledge and resource channels of marketing partners, to provide strong support for seven robots to open up new markets and expand sales channels.',
                                sort: 2
                            }],
                        type: 'image',
                        sort: 3
                    },
                    {
                        pcElementId: 407,
                        phoneElementId: '',
                        descList: [
                            {
                                descName: 'Corporate Customer',
                                sort: 1
                            }, {
                                descName: 'Sevnce Robotics has provided products, services and standardized solutions for thousands of enterprises in more than 40 countries and regions around the world, through the establishment of close cooperation with these enterprise customers, you can better understand the market demand, optimize products and services, improve customer satisfaction, so as to achieve common development of both sides.',
                                sort: 2
                            }],
                        type: 'image',
                        sort: 4
                    }
                ]
            // elmentList: []
        }
    },
    mounted() {
        // this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            this.$nextTick(() => {
                if (this.$store.state.pageset.pcshow) {
                    this.initPartnersSwiper();
                    this.initPartnersSwiperOne();
                    this.initPartnersSwiperCustom();
                }
            });
        });
        let arr = [];
        for (let i = 0; i < 56; i++) {
            arr.push(i);
        }
        for (let i = 0; i < arr.length; i += 8) {
            this.marketingList.push(arr.slice(i, i + 8))
        }
    },
    // 销毁滚轮事件
    destroyed() {
        // 销毁swiper
        this.partnersSwiper.destroy();
        this.partnersSwiperOne.destroy();
        this.partnersSwiperCustom.destroy();
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        // 初始化banner轮播图
        initPartnersSwiper() {
            let _that = this;
            this.partnersSwiper = new Swiper('.swiper-patner', {
                speed: 700,
                allowTouchMove: false,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 3,
                },
                centeredSlides: true,
                spaceBetween: 50,
                // slidesOffsetBefore: 40,
                loop: true,
                slidesPerView: 9,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
            window.onresize = function () {
                _that.initPartnersSwiper.update();
            }
        },

        initPartnersSwiperOne() {
            let _that = this;
            this.partnersSwiperOne = new Swiper('.swiper-patner-one', {
                // speed: 700,
                allowTouchMove: false,
                freeMode: true,
                lazy: {

                    loadPrevNext: true,
                    loadPrevNextAmount: 3,
                },
                centeredSlides: true,
                spaceBetween: -30,
                slidesOffsetBefore: 0,
                loop: true,
                slidesPerView: 4,
                navigation: {
                    nextEl: '.swiper-button-next-one',
                    prevEl: '.swiper-button-prev-one',
                },
                on: {},

            });
            window.onresize = function () {
                _that.initPartnersSwiperOne.update();
            }
        },
        initPartnersSwiperCustom() {
            let _that = this;
            this.partnersSwiperCustom = new Swiper('.swiper-patner-custom', {
                // speed: 700,
                allowTouchMove: false,
                freeMode: true,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 3,
                },
                centeredSlides: true,
                slidesOffsetBefore: 0,
                slidesPerView: 1,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next-one',
                    prevEl: '.swiper-button-prev-one',
                },
                on: {},

            });
            window.onresize = function () {
                _that.partnersSwiperCustom.update();
            }
        },
        openPdf() {
             this.$router.push('/pdfView')
        },
    }
}